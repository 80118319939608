<template>
  <section>
    <div style="position: relative">
      <div class="d-flex flex-column justify-center">
        <div class="">
          <div class="d-flex">
            <input
              v-on:keyup="keySend"
              rows="1"
              v-model="message"
              style="
                width: 100%;
                outline: none;
                padding: 10px;
                border-radius: 12px;
              "
              :style="
                dark == 1 ? 'background: #424242;' : 'background: #e5e5e5;'
              "
              placeholder="Write a comment"
            />
            <div class="">
              <v-btn @click="show = !show" icon color="#fff">😃</v-btn>
            </div>
            <div class="">
              <v-btn icon @click="send">
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <div style="position: relative" class="d-flex justify-end">
          <VEmojiPicker
            v-show="show"
            :style="{ width: '440px', height: '200' }"
            labelSearch="Search"
            lang="pt-BR"
            @select="onSelectEmoji"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
import { mapActions } from "vuex";

export default {
  props: ["id", "action", "replyId", "dark"],
  components: {
    VEmojiPicker,
  },
  data: () => ({
    show: false,
    message: "",
  }),
  methods: {
    ...mapActions("student", ["comment", "reply"]),

    onSelectEmoji(emoji) {
      this.message += emoji.data;
      this.show = false;
    },

    keySend(e) {
      if (e.keyCode === 13) {
        this.send();
      }
    },

    send() {
      if (this.action === "comment") {
        this.comment({
          id: this.id,
          comment: this.message,
        });
        this.message = "";
        return true;
      }

      if (this.action === "reply") {
        this.reply({
          topic_id: this.id,
          id: this.replyId,
          comment: this.message,
        });
        this.message = "";
        return true;
      }
    },
  },
};
</script>
