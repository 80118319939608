<template>
  <section>
    <v-sheet class="mb-10">
      <Input class="ma-10" :id="topic.id" action="comment" :dark="dark" />
      <div v-if="comments">
        <Reply
          v-for="item in comments"
          :key="item.id"
          :item="item"
          :topicId="topic.id"
          :dark="dark"
        />
      </div>
    </v-sheet>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Reply from "./Reply.vue";
import Input from "../course/Input.vue";
export default {
  props: ["topic", "comments", "dark"],
  components: {
    Reply,
    Input,
  },
  data: () => ({
    show: false,
    message: "",
    btn: null,
  }),
  methods: {
    ...mapActions("student", ["comment"]),

    onSelectEmoji(emoji) {
      this.message += emoji.data;
    },

    keySend(e) {
      if (e.keyCode === 13) {
        this.send();
      }
    },

    send() {
      this.comment({
        id: this.topic.id,
        comment: this.message,
      });
      this.message = "";
    },
  },
};
</script>