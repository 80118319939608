<template>
  <section id="material">
    <v-row justify="center" align="center" v-if="!loading" no-gutters>
      <v-col cols="12" xl="7" lg="10">
        <breadcrumbs :links="links" :dark="user.dark" />
        <v-card flat style="border-radius: 0px">
          <!--youtube -->
          <url
            v-if="topic.current.type == 'u'"
            :url="topic.current.material.replace('watch?v=', 'embed/')"
          />
          <!--videos -->
          <vid
            v-else-if="topic.current.type == 'v'"
            :file="`${topic.current.aws}/courses/${topic.current.lesson.course.uuid}/${topic.current.lesson.uuid}/${topic.current.uuid}/video/converted/${topic.current.material}_1080p.mp4`"
          />
          <!--docx -->
          <document
            v-else-if="topic.current.type == 'd'"
            :file="topic.current.document"
          />
          <!--virtual tour -->
          <url v-else :url="topic.current.material" />
          <!-- -->
        </v-card>

        <v-tabs v-model="tab" color="primary">
          <v-tab v-for="item in items" :key="item" class="caption">
            {{ item }}
          </v-tab>
          <v-spacer />
          <div class="d-flex align-center">
            <v-btn
              v-if="topic.prev"
              :color="user.dark == 1 ? 'white' : 'red'"
              text
              :to="{ name: 'Student Material', params: { uuid: topic.prev } }"
            >
              <v-icon left>mdi-skip-previous</v-icon>
              Prev
            </v-btn>
            <v-btn
              v-if="topic.next"
              color="info"
              text
              :to="{ name: 'Student Material', params: { uuid: topic.next } }"
            >
              Next
              <v-icon right>mdi-skip-next</v-icon>
            </v-btn>
          </div>
        </v-tabs>
        <v-tabs-items v-model="tab" >
          <v-tab-item v-for="item in items" :key="item" >
            <v-card flat>
              <v-card-text>
                <AssessmentCard
                  v-if="item === 'Assessments'"
                  :current="topic.current"
                />

                <Comment
                  v-if="item === 'Comments'"
                  :topic="topic.current"
                  :comments="stateComments"
                  :dark="user.dark"
                />

                <!-- <LeaderBoard v-if="item === 'Leaderboard' && topic.current.type == 't'"/> 
                <Raffle v-if="item === 'E Raffle' && topic.current.type == 't'"/>  -->
                <!-- <Chat
                  v-if="item === 'Chat'"
                  :sponsors="sponsors"
                  :dark="user.dark"
                /> -->
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      
    </v-row>
    <div v-else class="body-1">Loading...</div>
  </section>
</template>

<script>
import { mapActions, mapMutations, mapState } from "vuex";
import AssessmentCard from "../components/course/AssessmentCard.vue";
import Comment from '../components/course/Comment.vue'
// import LeaderBoard from '../components/material/LeaderBoard.vue'
// import Raffle from '../components/material/ERaffle.vue'
// import io from "socket.io-client";
// import Chat from "../components/course/Chat.vue";

export default {
  components: {
    AssessmentCard,
    Comment,
    // LeaderBoard,
    // Raffle
    // Chat,
  },
  data: () => ({
    loading: true,
    items: ["Assessments", "Comments"],
    links: [],
    tab: null,
    on_chat: false,
    message: '',
    socket: "",
  }),
  computed: {
    ...mapState("student", {
      topic: (state) => state.topic,
      stateComments: (state) => state.comments,
      user: (state) => state.user,
      sponsors: (state) => state.sponsors,
      chat: (state) => state.chat,
      
    }),
  },
  mounted() {
    // let vm = this

    this.topicsGetAction(this.$route.params.uuid).then(() => {
      this.loading = false;
      this.comments(this.topic.current.id);
      this.setLinks();
      // if(this.topic.current.type == 't') {
      //   this.items.push('Leaderboard','E Raffle')
      // }
      // vm.scrollBottom()
    });

    // this.getSponsorsAction()
    
    // this.socket = io.connect(this.$helpers.socket_end_point(), {});

    // this.socket.on("connect", () => {
    //   console.log("start connection");
    // });

    // let iochat = `${this.user.uuid}:${this.$helpers.tenant()}-chat-channel:App\\Events\\ChatEvent`

    // this.socket.on(iochat, function (message) {
    //   vm.showChatAction(message.data.from).then(() => {
    //     vm.scrollBottom()
    //   });
    // })
  },
  methods: {
    ...mapActions("student", [
      "topicsGetAction",
      "comments",
      // "getSponsorsAction",
      // "showChatAction",
      // "sendChatAction",
    ]),

    ...mapMutations("student", [
      "chatMutation"
    ]),

    setLinks() {
      this.links = [];
      this.links.push(
        {
          name: this.topic.current.lesson.course.title,
          link: true,
          to: {
            name: "Student Course Details",
            params: this.topic.current.lesson.course.uuid,
          },
        },
        {
          name: this.topic.current.title,
          link: false,
          to: {
            name: "",
            params: "",
          },
        }
      );
    },

    // convo(uuid) {
    //   console.log(uuid);
    //   this.showChatAction(uuid).then(() => {
    //     this.on_chat = true
    //     this.scrollBottom()
    //   }) 
    // },

    // sendMessage() {
    //   this.sendChatAction({
    //     uuid: this.chat.uuid,
    //     message: this.message
    //   }).then(() => {
    //     this.message = ''
    //   })
    // },

    // closeChat() {
    //   this.on_chat = false,
    //   this.message = '',
    //   this.chatMutation([])
    // },

    // scrollBottom() {
    //   var vm = this
    //   var container = vm.$el.querySelector("#chatdrawer");
    //   container.scrollTop = container.scrollHeight;
    // }
  },
  watch: {
    $route(to) {
      console.log(to);
      this.loading = true;
      this.topicsGetAction(this.$route.params.uuid).then(() => {
        this.loading = false;
        this.comments(this.topic.current.id);
        this.setLinks();
        if(this.topic.current.type == 't') {
          this.items.push('Leaderboard','E Raffle')
        }
      });
    },
  },
};
</script>