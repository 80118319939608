<template>
  <section>
    <v-sheet class="d-flex align-start ma-2 pa-3" style="border-radius: 12px">
      <div class="mr-2">
        <v-avatar size="40" color="success" />
      </div>
      <v-sheet
        class="d-flex flex-column secondary px-3 pb-2 pt-1"
        width="100%"
        style="border-radius: 12px"
      >
        <div class="d-flex">
          <small class="caption grey--text">
            {{ `${item.user.firstname} ${item.user.lastname}` }}
          </small>
          <v-spacer />
          <small class="caption grey--text">
            {{ $helpers.dateDiff(item.created_at) }}
          </small>
        </div>

        <div class="body-2" v-html="item.comment" />
      </v-sheet>
    </v-sheet>
    <div class="d-flex ml-14" style="margin-top: -20px">
      <v-btn
        @click="comments = !comments"
        x-small
        text
        class="caption"
        style="text-transform: capitalize"
      >
        <v-icon size="16" :class="item.comments.length == 0 ? 'mr-1' : ''"
          >mdi-reply</v-icon
        >
        <span v-if="item.comments.length > 0" class="mr-1">{{
          item.comments.length
        }}</span>
        View Reply
      </v-btn>
      <v-btn
        @click="replys = !replys"
        text
        class="caption"
        x-small
        color="info"
        style="text-transform: capitalize"
      >
        Comment
      </v-btn>
    </div>
    <div v-if="replys">
      <Input
        class="ml-16 my-2"
        :replyId="item.id"
        :id="topicId"
        :dark="dark"
        action="reply"
      />
    </div>
    <div v-if="item.comments.length > 0 && comments" class="ml-10">
      <Reply
        v-for="comment in item.comments"
        :key="comment.id"
        :item="comment"
        :topicId="topicId"
        :dark="dark"
      />
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Input from "./Input.vue";

export default {
  name: "Reply",
  props: ["item", "topicId", "dark"],
  components: {
    Input,
  },
  data: () => ({
    replys: false,
    comments: false,
  }),
  methods: {
    ...mapActions("student", ["comment"]),
  },
};
</script>