<template>
  <v-row class="mt-5">
    <v-col cols="12" lg="3" 
    v-for="assessment in current.assessments"
    :key="assessment.id"
    class="pa-3 mt-2">
      <v-card flat max-width="200" class="mx-auto"
      @click="take(assessment)" 
      style="cursor: pointer">
        <div class="text-center">
          <v-avatar tile size="100">
            <v-icon size="100" :color="assessment.assessment_score ? 'success' : 'secondary-2'">
              mdi-file-account-outline
            </v-icon>
          </v-avatar>
        </div>
        <v-card-text class="mt-0 pt-0">
          <div class="text-center body-1 font-weight-bold black--text">
            {{ assessment.title }} <v-icon size="16" :color="assessment.assessment_score ? 'success' : ''">
              {{
                assessment.assessment_score
                  ? "mdi-checkbox-marked"
                  : "mdi-checkbox-blank"
              }}
            </v-icon>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
  <!-- <v-row class="assessment-card">
    <v-col cols="12" lg="8">
      <v-card
        v-for="assessment in current.assessments"
        :key="assessment.id"
        class="pa-3 mt-2"
      >
        <div class="d-flex justify-space-between align-center">
          <div class="caption">
            <v-icon
              size="18"
              class="mr-5"
              :color="assessment.assessment_score ? 'success' : ''"
            >
              {{
                assessment.assessment_score
                  ? "mdi-checkbox-marked"
                  : "mdi-checkbox-blank"
              }}
            </v-icon>
            {{ assessment.title }}
          </div>
          <div class="">
            <v-btn
              text
              outlined
              x-small
              @click="take(assessment)"
            >
              {{ assessment.assessment_score ? "Retake" : "Take Now" }}
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-col>
  </v-row> -->
</template>

<script>
export default {
  props: ["current"],
  methods: {
    take(assessment) {
      // if(assessment.assessment_score) return true
      this.$router.push({
        name: 'Student Assessment',
        params: { uuid: assessment.uuid, type: 'sub-lesson' },
      })
    }
  }
};
</script>